@import "~notosans-fontface/css/notosans-fontface-allweight.css";
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  background: #fafafa;
  font-size: 16px;
  line-height: 1.5;
  overscroll-behavior-x: contain;
}

.tiny {
  font-size: 0.75rem;
  line-height: 1.5;
}

.small {
  font-size: 0.875rem;
  line-height: 1.5;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-wrap-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

label {
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 500;
}
